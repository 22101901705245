import React from "react"
import { WeddingProvider } from "../../context/WeddingProvider"
import { WeddingHomepage } from "./WeddingHomepage"

interface IProps {
    // children: ReactNode;
}

export const WeddingLayout: React.FC<IProps> = ()=>{
    return (
        <WeddingProvider>
            <WeddingHomepage />
        </WeddingProvider>
    )
}


