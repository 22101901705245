// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.WeddingHomepage_backgroundPictureContainer__xCkuv {
    position: relative; 
    /* min-height: 200px; */
    /* height: 70dvh; */
    min-width: calc(100% - 120px);
    /* background-size: contain;
    background-position: center;
    background-repeat: no-repeat; */
    display: flex; 
    justify-content: center; 
    align-items: center; 
    color: rgb(255, 255, 255); 
    font-size: 24px; 
    font-weight: bold;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
    /* background-color: silver; */
    /* aspect-ratio: 16/9; */

}
.WeddingHomepage_backgroundPicture__EAUmR {
    z-index: 1;
    width: 100%;
    max-height: 70dvh;
    object-fit: cover;
    /* border: 20px solid white; */
    border-radius: 10px;
}

.WeddingHomepage_backgroundPictureContainer__xCkuv::before {
    content: ''; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 1;
    display: block;
    pointer-events: none;
}

.WeddingHomepage_backgroundPictureContainer__xCkuv span {
    position: absolute;
    z-index: 2; 
    padding: 10px;
}

.WeddingHomepage_html__4mXEr > p {
    margin: 0;
}

`, "",{"version":3,"sources":["webpack://./src/pages/wedding/WeddingHomepage.module.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,6BAA6B;IAC7B;;mCAE+B;IAC/B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB,2CAA2C;IAC3C,8BAA8B;IAC9B,wBAAwB;;AAE5B;AACA;IACI,UAAU;IACV,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,UAAU;IACV,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,SAAS;AACb","sourcesContent":["\n.backgroundPictureContainer {\n    position: relative; \n    /* min-height: 200px; */\n    /* height: 70dvh; */\n    min-width: calc(100% - 120px);\n    /* background-size: contain;\n    background-position: center;\n    background-repeat: no-repeat; */\n    display: flex; \n    justify-content: center; \n    align-items: center; \n    color: rgb(255, 255, 255); \n    font-size: 24px; \n    font-weight: bold;\n    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);\n    /* background-color: silver; */\n    /* aspect-ratio: 16/9; */\n\n}\n.backgroundPicture {\n    z-index: 1;\n    width: 100%;\n    max-height: 70dvh;\n    object-fit: cover;\n    /* border: 20px solid white; */\n    border-radius: 10px;\n}\n\n.backgroundPictureContainer::before {\n    content: ''; \n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    /* background-color: rgba(0, 0, 0, 0.5); */\n    z-index: 1;\n    display: block;\n    pointer-events: none;\n}\n\n.backgroundPictureContainer span {\n    position: absolute;\n    z-index: 2; \n    padding: 10px;\n}\n\n.html > p {\n    margin: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgroundPictureContainer": `WeddingHomepage_backgroundPictureContainer__xCkuv`,
	"backgroundPicture": `WeddingHomepage_backgroundPicture__EAUmR`,
	"html": `WeddingHomepage_html__4mXEr`
};
export default ___CSS_LOADER_EXPORT___;
