import React, { useState, useRef, useEffect } from "react";
import BackgroundImage from "../../components/BackgroundImage";
import styles from "./Login.module.css";
import { TextField, Typography } from "@mui/material";

type Focus = 1 | 2 | 3 | 4;

interface Props {
    weddingId?: string
    loginGroup: (code: string) => Promise<boolean>
}

export const Login: React.FC<Props> = ({weddingId, loginGroup}) => {
    const [focus, setFocus] = useState<Focus>(1);
    const [values, setValues] = useState<string[]>(["", "", "", ""]);
    const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
    const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false)

    const handleClear = ()=>{
        setValues(["", "", "", ""])
        setFocus(1)
    }

    const login = async()=>{
        if (!weddingId) return
        if (values[0] && values[1] && values[2] && values[3]){
            const entryCode: string = values.join("")
            const res = await loginGroup(entryCode)
            if (!res){
                setInvalidCredentials(true)
                handleClear()
            }
        }
    }

    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>,
        currentIndex: Focus
    ) => {
        switch (event.key) {
            case "Backspace":
                event.preventDefault();
                if (!values[currentIndex - 1] && currentIndex > 1) {
                    setFocus(currentIndex - 1 as Focus);
                }
                setValues((prev) => {
                    const updated = [...prev];
                    updated[currentIndex - 1] = "";
                    return updated;
                });
                break;
            case "ArrowLeft":
                if (currentIndex > 1) {
                    setFocus(currentIndex - 1 as Focus);
                }
                break;
            case "ArrowRight":
                if (currentIndex < 4) {
                    setFocus(currentIndex + 1 as Focus);
                }
                break;
            default:
                break;
        }
    };

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        currentIndex: Focus
    ) => {
        const value = event.target.value;
        if (value.length > 1) return;
        const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
        setValues((prev) => {
            const updated = [...prev];
            updated[currentIndex - 1] = sanitizedValue;
            return updated;
        });
        if (sanitizedValue && currentIndex < 4) {
            setFocus(currentIndex + 1 as Focus);
        }
    };

    const handleClick = (index: Focus) => {
        setFocus(index);
    };

    useEffect(() => {
        if (inputRefs.current[focus - 1]) {
            inputRefs.current[focus - 1]?.focus();
        }
    }, [focus]);

    useEffect(()=>{
        login()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[values])

    return (
        <>
            <BackgroundImage imageUrl="/images/wallpaper1.webp" />
            <div className={styles.structure}>
                <div className={styles.container}>
                    <Typography variant="h6" marginBottom={1}>Please enter the code</Typography>
                    <Typography variant="body2" marginBottom={3} width={250}>You can find it in the invitation, or automatically enter through the qrcode</Typography>
                    {[1, 2, 3, 4].map((i) => (
                        <TextField
                            key={i}
                            required
                            variant="outlined"
                            style={{ width: "50px", marginRight: "10px" }}
                            inputRef={(el) => (inputRefs.current[i - 1] = el)}
                            value={values[i - 1]}
                            onKeyDown={(e) => handleKeyDown(e, i as Focus)}
                            onChange={(e) => handleChange(e, i as Focus)}
                            onClick={() => handleClick(i as Focus)}
                            slotProps={{ 
                                htmlInput: {
                                    maxLength: 1,
                                    style: { textAlign: "center", fontSize: "20px" },
                                },
                                input: {
                                    autoComplete: "off",
                                },
                            }}
                        />
                    ))}
                    {invalidCredentials && <div>senha invalida</div>}
                </div>
            </div>
        </>
    );
};
