export const fileToBlob = async(file: File): Promise<Blob>=>{
    return new Blob([file], {
        type: file.type
    });
}

export const blobToUrl = (blob: Blob | null): string | undefined => {
    if (!blob) return undefined;
    return URL.createObjectURL(blob);
};

export const fileToBase64 = (file: File): Promise<string | null> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.result) {
                const base64String = reader.result.toString().split(",")[1]; 
                resolve(base64String);
            } else {
                resolve(null);
            }
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
};

export const base64ToBlob = (base64: string, mimeType = ''): Blob => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
};

