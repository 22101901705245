import React from 'react';
import { Box } from '@mui/material';
import Foto from "./Foto1.jpg"

const Instruction1 = () => (
    <Box sx={{ width: 'auto', height: '280px' }}>
        <img src={Foto} alt="Descrição da Foto" style={{ width: '100%', height: '100%' }} />
    </Box>
);

export default Instruction1;
