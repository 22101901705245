import React, { useState } from "react";
import BackgroundImage from "../../components/BackgroundImage";
import { Navigation } from "./components/Navigation";
import { useWedding } from "../../context/WeddingProvider";
import styles from "./WeddingHomepage.module.css"
import { PictureWithName } from "./components/PictureWithName";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ResponsiblePaper } from "./components/ResponsiblePaper";
import DOMPurify from 'dompurify';

import { GuestCard } from "./components/GuestCard";
import { GiftList } from "./components/GiftList";
import { FontFamilyTitle } from "../../utils/Wedding";

export const WeddingHomepage: React.FC = () => {
    const theme = useTheme();
    // const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { wedding, weddingId, toggleWillAttend } = useWedding()

    const urlPicture: string | undefined = weddingId ? `/api/weddings/${weddingId}/background` : "/broken-image.jpg"

    const sanitizedHTMLDescription = DOMPurify.sanitize(wedding?.description || "");
    const sanitizedHTMLInfos = DOMPurify.sanitize(wedding?.infos || "");

    const fontTitle: FontFamilyTitle = `${wedding?.fontFamilyTitle || "Bilbo Swash Caps"}`
    
    return (
        <>
            <div id='home' style={{visibility: 'hidden', position: 'absolute', top: 0}}></div>
            <Navigation />
            <BackgroundImage imageUrl="/images/wallpaper1.webp" />
            <Box sx={{maxWidth: '80em', margin: 'auto', marginTop: isMobile ? '50px' : '104px'}}>
                <Box display={'flex'} flexDirection={'column'} gap={5} paddingBlockEnd={'2rem'}>
                    
                    {/* Picture */}
                    <PictureWithName url={urlPicture} title={wedding?.name} fontTitle={fontTitle}/>
                    
                    {/* Welcome Description */}
                    <ResponsiblePaper fullWidth widthReduction={'20px'} centerContent autoMargin
                        style={{backgroundColor: theme.palette.description.background,color: theme.palette.description.color, padding: '2rem 1rem'}}
                    >
                        <div className={styles.html} style={{maxWidth: '50rem'}} dangerouslySetInnerHTML={{ __html: sanitizedHTMLDescription }}></div>
                    </ResponsiblePaper>
                    
                    {/* Information */}
                    <Typography id='infos' variant="h3" fontFamily={`${fontTitle}, cursive`} textAlign={"center"}>Informationen</Typography>
                    <ResponsiblePaper fullWidth widthReduction={'20px'} centerContent autoMargin style={{padding: '2rem 1rem'}}>
                        <div className={styles.html} dangerouslySetInnerHTML={{ __html: sanitizedHTMLInfos }}></div>
                    </ResponsiblePaper>

                    {/* Group actions */}
                    <Typography id='group' variant="h3" fontFamily={`${fontTitle}, cursive`} textAlign={"center"}>Auswählen</Typography>
                    <ResponsiblePaper id='infos' fullWidth widthReduction={'20px'} centerContent autoMargin style={{padding: '2rem 1rem'}}>
                        <Box display={"inline-flex"} gap={2} flexWrap={"wrap"}>
                            {wedding?.guestGroup?.guests?.map((g, i) => {
                                return <GuestCard key={i} guest={g} onWillAttendChange={toggleWillAttend}/>
                            })}
                        </Box>
                    </ResponsiblePaper>

                    {/* Gift list */}
                    <Typography id='gifts' variant="h3" fontFamily={`${fontTitle}, cursive`} textAlign={"center"}>Geschenke</Typography>
                    <ResponsiblePaper id='infos' fullWidth widthReduction={'20px'} centerContent autoMargin style={{padding: '2rem 1rem'}}>
                        <GiftList />
                    </ResponsiblePaper>


                </Box>
            </Box>
        </>
    );
};

