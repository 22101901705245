import { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom";
import { Wedding } from "../utils/Wedding";
import { useAuth } from "./AuthProvider";
import { Login } from "../pages/groupUser/Login";
import { Button } from "@mui/material";

interface IWeddingContext {
    wedding: Wedding | null;
    isLoading: boolean;
    error: any;
    weddingId?: string;
    loginGroup: (code: string | undefined)=> Promise<boolean>;
    toggleWillAttend: (guestId: number)=> void;
    setWedding: (wedding: Wedding | null)=>void;
}

const weddingContext = createContext<IWeddingContext | null>(null)

export const useWedding = () =>{
    const context = useContext(weddingContext);
    if (!context) {
        throw new Error("useWeddingProvider must be used within a WeddingProvider");
    }
    return context;
}

interface IProps {
    children: ReactNode;
}

export const WeddingProvider: React.FC<IProps> = ({children})=>{
    const { weddingId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const { groupUser, loginWithGroup, refetch } = useAuth()
    const [wedding, setWedding] = useState<Wedding | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);
    const [notFoundFromLogin, setNotFoundFromLogin] = useState<boolean>(false)

    const entryCode = searchParams.get("code");

    const loginGroup = async(code: string | undefined): Promise<boolean>=>{
        if (!code || !weddingId || groupUser) return false

        const response = await loginWithGroup(code, weddingId)
        if (response.success){
            searchParams.delete("code")
            setSearchParams(searchParams)
            refetch()
            return true
        }
        
        // If unauthorized, return error
        // If not found, render NotFound page before return something
        if (response.error === "UNAUTHORIZED"){
            return false
        }
        setNotFoundFromLogin(true)
        return false
    }

    useEffect(()=>{
        if (groupUser) return

        // Sign in with entry code
        if (entryCode && weddingId){
            loginGroup(entryCode)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[entryCode, weddingId, groupUser])
    
    const fetchWedding = async()=>{
        try {
            const endpoint = `/api/weddings/${weddingId}`;
            const response = await fetch(endpoint, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (!response.ok) { 
                console.log(response)
                throw Error(response.status.toString());
            } 
            const data: Wedding = await response.json();
            console.log(data)
            setWedding(data);
            setError(null);
                
        } catch(err) {
            console.log(err)
            setIsLoading(false);
            if (err instanceof Error) {
                setError(err.message || 'An unknown error occurred');
            } else {
                setError('An unknown error occurred');
            }
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        weddingId  && fetchWedding()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weddingId, groupUser])

    const toggleWillAttend = (guestId: number)=>{
        const groupId = wedding?.guestGroup?.id

        if (!groupId) return
        const guest = wedding?.guestGroup?.guests?.find(g => g.id === guestId)

        if (!guest) return

        fetch(`/api/weddings/${weddingId}/groups/${groupId}/guests/${guestId}/willAttend/toggle`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
            if (response.ok){
                setWedding(prevWedding => {
                    if (!prevWedding || !prevWedding.guestGroup) return prevWedding;
            
                    const updatedGuestGroup = {
                        ...prevWedding.guestGroup,
                        guests: prevWedding.guestGroup.guests?.map(guest =>
                            guest.id === guestId ? { ...guest, willAttend: !guest.willAttend } : guest
                        )
                    };
            
                    return {
                        ...prevWedding,
                        guestGroup: updatedGuestGroup
                    };
                });
            }
        }).catch(err => {
            alert(err.toString())
        })
    }

    

    const values: IWeddingContext = {
        wedding,
        isLoading,
        error,
        weddingId,
        loginGroup,
        toggleWillAttend,
        setWedding
    }

    if (notFoundFromLogin) return <>Not found from login <Button onClick={()=>setNotFoundFromLogin(false)}>Voltar</Button></>
    if (isLoading) return <>loading...</>
    if (!groupUser) return <Login weddingId={weddingId} loginGroup={loginGroup}/>
    if (!wedding) return <>Wedding not found</>
    if (error) return <>Error wedding context {error}</>

    return <weddingContext.Provider value={values}>{children}</weddingContext.Provider>
}