import { Paper, SxProps, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

interface IProps {
    children?: React.ReactNode;
    fullWidth?: boolean;
    style?: SxProps;
    centerContent?: boolean;
    elevation?: number;
    widthReduction?: string | number;
    autoMargin?: boolean;
    id?: string
}

export const ResponsiblePaper: React.FC<IProps> = ({ 
    children, 
    fullWidth = false, 
    style = {}, 
    centerContent = false,
    elevation = 1,
    widthReduction = '0px',
    autoMargin = false,
    id,
}) => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const sxData = (): SxProps => {

        const getWidth = ()=>{
            if (fullWidth){
                if(isMobile || isTablet){
                    return `calc(100% - ${widthReduction || '0'})`
                }
                return `calc(100% - ${widthReduction || '0'})`
            }
            return ''
        }
        const baseStyles: SxProps = {
            ...autoMargin && {margin: '0 auto'},
            padding: theme.spacing(2),
            width: getWidth(),
            ...style
        };

        if (centerContent) {
            return {
                ...baseStyles,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center', 
             
            };
        }

        return baseStyles;
    };

    return (
        <Paper id={id} sx={sxData()} elevation={elevation}>
            {children}
        </Paper>
    );
};
