import React, { useEffect } from "react"
import { CreateWedding } from "./CreateWedding"
import { useAuth } from "../../context/AuthProvider"
import { useNavigate } from "react-router-dom"

interface IProps {
    // children: ReactNode;
}

export const CreateWeddingLayout: React.FC<IProps> = ()=>{
    
    const { user, isLoading } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!user && !isLoading) {
            navigate("/signin")
        }
    }, [user, navigate, isLoading]);
    
    if (!user) {
        return <>Loading....</>
    }

    return (
        <CreateWedding />
    )
}


