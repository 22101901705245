import React, { ReactNode } from "react"
import { WeddingAdminProvider } from "../../context/WeddingAdminProvider"

interface IProps {
    children: ReactNode;
}

export const WeddingAdminLayout: React.FC<IProps> = ({children})=>{
    return (
        <WeddingAdminProvider>
            {children}
            
        </WeddingAdminProvider>

    )
}


