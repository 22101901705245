import React, { useCallback, useState } from "react";
import {
    TextField,
    Button,
    Typography,
    Box,
    Grid2,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { ResponsiblePaper } from "../wedding/components/ResponsiblePaper";
import { Wedding } from "../../utils/Wedding";

export const CreateWedding: React.FC = () => {
    const theme = useTheme();
    // const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [errors, setErrors] = useState({
        name: false,
        displayUrl: false,
    });
    
    const [wedding, setWedding] = useState<Wedding>({
        name: "",
        displayUrl: "",
        description: "",
        backgroundPicture: null,
        infos: "",
        weddingAddress: "",
        weddingDate: null,
        groups: [],
        ageOptions: [],
        foodOptions: [],
        questions: [],
        giftOptions: [],
        lists: [],
        confirmBeforeDate: null,
        giftsDescription: '',
    });

    const addWedding = useCallback(async()=>{
        fetch("/api/weddings", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(wedding)
        }).then((response)=>{
            if (response.ok){
                response.json()
                alert("created")
                return
            }
            alert("error" + response.status)
        }).then((dataResponse)=>{
            
        }).catch((err)=>{
            alert(err)
        })
    }, [wedding])

    const validateFields = (): boolean => {
        const newErrors = {
            name: !wedding.name.trim(),
            displayUrl: !wedding.displayUrl.trim(),
        };
        setErrors(newErrors);
        return !Object.values(newErrors).includes(true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setWedding((prev) => ({ ...prev, [name]: value }));
        setErrors(prev => ({...prev, [name]: false}))
    };

    const handleSubmit = () => {
        if (!validateFields()) return
        addWedding()
    };

    return (
        <Box sx={{ padding: isMobile ? 0 : 4 }}>
            <Typography variant="h4" gutterBottom>
                Create Wedding
            </Typography>
            <ResponsiblePaper autoMargin={!!isMobile} fullWidth widthReduction={isMobile ? '20px' : ''} elevation={3} style={{ padding: isMobile ? '10px' : '15px', maxWidth: '1000px'  }}>
                <Grid2 container spacing={3}>
                    <Grid2 size={12}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            value={wedding.name}
                            onChange={handleInputChange}
                            error={errors.name}
                            required
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <TextField
                            fullWidth
                            label="Display URL"
                            name="displayUrl"
                            value={wedding.displayUrl}
                            error={errors.displayUrl}
                            onChange={handleInputChange}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Save Wedding
                        </Button>
                    </Grid2>
                </Grid2>
            </ResponsiblePaper>
        </Box>
    );
};

