import { Card, CardHeader, Avatar, Divider, CardContent, Box, Typography, ToggleButtonGroup, ToggleButton, useTheme, useMediaQuery } from '@mui/material'
import { green, red } from '@mui/material/colors'
import React from 'react'
import { Guest } from '../../../utils/Wedding';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import PersonIcon from '@mui/icons-material/Person';

interface IProps {
    onWillAttendChange: (guestId: number)=>void;
    guest: Guest
}

export const GuestCard: React.FC<IProps> = ({ onWillAttendChange, guest })=>{
    const theme = useTheme();
    // const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    
    const handleChangeWillAttend = (guestId?: number)=>{
        guestId && onWillAttendChange(guestId)
    }

    return (
        <Card sx={{ minWidth: '15rem', margin: '1rem', boxShadow: 3 }}>
            <CardHeader
                title={guest.name}
                avatar={
                    <Avatar alt={guest.name} src="/broken-image.jpg">
                        <PersonIcon />
                    </Avatar>
                }
                sx={{padding: '0.5em 1em'}}
            />
            <Divider />
            <CardContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={2}
                >
                    <Typography variant="body2" textAlign="center" sx={{color: 'text.secondary'}}>
                        {guest.willAttend ? 'Ich bin dabei!' : 'Ich bin nicht dabei :('}
                    </Typography>
                    <ToggleButtonGroup
                        value={guest.willAttend}
                        exclusive
                        onChange={() => handleChangeWillAttend(guest.id)}
                        size="small"
                        aria-label="attendance confirmation"
                    >
                        <ToggleButton
                            value={true}
                            aria-label="Confirm attendance"
                            sx={{
                                backgroundColor: guest.willAttend ? green[400] : undefined,
                                color: guest.willAttend ? '#fff' : undefined,
                                '&.Mui-selected': {
                                    backgroundColor: green[500],
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: green[300],
                                    },
                                },
                            }}
                        >
                            <CheckIcon />
                        </ToggleButton>
                        <ToggleButton
                            value={false}
                            aria-label="Decline attendance"
                            sx={{
                                backgroundColor: !guest.willAttend ? red[400] : undefined,
                                color: !guest.willAttend ? '#fff' : undefined,
                                '&.Mui-selected': {
                                    backgroundColor: red[500],
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: red[300],
                                    },
                                },
                            }}
                        >
                            <DoNotDisturbIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </CardContent>
        </Card>
    )
}