import { useMediaQuery, AppBar, Toolbar, Box, Button, Fab, Drawer, List, ListItem, ListItemButton, ListItemText, useTheme } from "@mui/material";
import { useState } from "react";
import { UserMenu } from "./UserMenu";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Link } from "react-scroll";


export const Navigation = ()=>{
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };

    const navItems = [
        {label: 'Home', link: 'home'}, 
        {label: 'Informationen', link: 'infos'}, 
        {label: 'Auswählen', link: 'group'}, 
        {label: 'Geschenke', link: 'gifts'}, 
    ];

    // Sidebar
    if (isMobile){
        return (
            <>  
                <Fab 
                    sx={{
                        borderRadius: 1, 
                        backgroundColor: theme.palette.floatingButton.background,
                        height: '32px',
                        width: '32px',
                        minHeight: 0,
                        minWidth: 0,
                        display: "inline-flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'fixed',
                        left: '10px',
                        top: '10px'
                    }} 
                    onClick={toggleDrawer(true)} >
                    <MenuIcon sx={{width: '25px', height: '25px', color: theme.palette.floatingButton.color}}/>
                </Fab>
                <Drawer 
                    anchor="left" 
                    open={drawerOpen} 
                    onClose={toggleDrawer(false)}
                    PaperProps={{sx: {width: "70%"}}}
                >
                    <List>
                    {navItems.map((item, index) => (
                        <ListItem key={index} onClick={toggleDrawer(false)}>
                            <Link key={item.label} to={item.link} spy smooth duration={500} onClick={() => setDrawerOpen(false)} style={{width: '100%'}}>
                                <ListItemButton >
                                    <ListItemText primary={item.label} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    ))}
                    </List>
                </Drawer>
            </> 
        )
    }

    // Navbar
    return (
        <AppBar position="fixed" sx={{width: '100%', backgroundColor: '#ffffff00'}}>
            <Toolbar sx={{
                    backgroundColor: theme.palette.navbar?.background,
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                <Box 
                    sx={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "center",
                    }}
                >
                    {navItems.map((item) => (
                        <Link key={item.label} to={item.link} offset={-74} spy smooth duration={500}>
                            <Button key={item.label} sx={{ color: theme.palette.navbar?.text, textTransform: 'none' }}>
                                {item.label}
                            </Button>
                        </Link>
                    ))}
                </Box>
                <UserMenu />
            </Toolbar>
        </AppBar>
    );
}