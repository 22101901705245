import { Box, AvatarGroup, Avatar, Tooltip, Typography, Accordion, AccordionSummary, AccordionDetails, Paper, Chip } from "@mui/material"
import React, { useState } from "react"
import { AgeTypeList } from "../../../../utils/Wedding"
import ElderlyIcon from '@mui/icons-material/Elderly';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useWeddingAdmin } from "../../../../context/WeddingAdminProvider";
import { green, red } from "@mui/material/colors";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ListIcon from '@mui/icons-material/List';

interface IProps {

}

export const TableGroups: React.FC<IProps> = ({})=>{
    const { wedding } = useWeddingAdmin()

    return (
        <Box mt={4}>
            {wedding?.groups?.sort((a, b)=> a.name.localeCompare(b.name)).map((group) => {
                const gifts: string[] = wedding.giftOptions.reduce<string[]>((acc, g) => {
                    const purchasedGiftName = g?.buyers?.some(b => b.id === group.id) ? g.name : null;
                    if (purchasedGiftName) {
                        acc.push(purchasedGiftName);
                    }
                    return acc;
                }, []);
                return <Accordion key={group.id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box display={"inline-flex"} alignItems={"center"} sx={{width: '100%', paddingX: '20px'}}>
                            <Typography sx={{width: '100%'}}>{group.name}</Typography>
                            <AvatarGroup max={15} sx={{alignSelf: "flex-start", "& .MuiAvatarGroup-avatar": { width: 24, height: 24, fontSize: 14 }}}>
                                {(group.guests || []).sort((a,b)=>a.name.localeCompare(b.name)).map((guest, indexGuest) => {
                                    return <Tooltip 
                                            title={<>
                                                <Box display={"flex"} flexDirection={"column"}>
                                                    <Typography variant="caption">Name: {guest.name}</Typography>
                                                    <Typography variant="caption">Will Attend: {guest.willAttend ? "Yes" : "No"}</Typography>
                                                    <Typography variant="caption">Age group: {guest.selectedAgeOption?.description || "Not selected"}</Typography>
                                                </Box>
                                            </>}
                                        >
                                            <Avatar key={indexGuest} 
                                                sx={{ width: 24, height: 24, bgcolor: guest.willAttend ? green[500] : red[800] }} src="" 
                                            >
                                                {guest.selectedAgeOption?.type === AgeTypeList.ERWACHSENE.key ? 
                                                    <ElderlyIcon />
                                                : guest.selectedAgeOption?.type === AgeTypeList.KIND.key ? 
                                                    <EmojiPeopleIcon />
                                                : guest.selectedAgeOption?.type === AgeTypeList.BABY.key ? 
                                                    <ChildCareIcon />
                                                : <QuestionMarkIcon />}
                                            </Avatar>
                                        </Tooltip>
                                })}
                            </AvatarGroup>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box display={'inline-flex'} alignItems={'center'} gap={2} flexWrap={"wrap"}>
                            {/* Gifts */}
                            <Paper elevation={2} sx={{display: "flex", flexDirection: "column", justifyContent: "center", 
                                        alignItems: "center", width: 'fit-content', paddingX: 2, paddingY: 1}}>
                                <CardGiftcardIcon />
                                <Box display={'inline-flex'} alignItems={'center'} gap={2} flexWrap={"wrap"} sx={{width: 'fit-content'}} mt={2}>
                                    {gifts.length > 0 && gifts.map((giftName, i)=>(
                                            <Chip key={i} label={giftName} variant="outlined" />
                                        ))
                                    }
                                    {gifts.length < 1 && <Typography variant="caption">No gifts selected</Typography>}
                                </Box>
                            </Paper>

                            {/* Lists */}
                            {wedding.lists.sort((a, b)=>a.name.localeCompare(b.name)).map((list, indexList)=>{
                                return (
                                    <Paper key={indexList} elevation={2} 
                                        sx={{display: "flex", flexDirection: "column", justifyContent: "center", 
                                            alignItems: "center", width: 'fit-content', paddingX: 2, paddingY: 1}}>
                                        <Box display={'inline-flex'} alignItems={'center'} gap={2}>
                                            <ListIcon />
                                            <Typography>{list.name}</Typography>
                                        </Box>
                                        <Box display={'inline-flex'} alignItems={'center'} gap={2} flexWrap={"wrap"} sx={{width: 'fit-content'}} mt={2}>
                                            {(list.items.filter(i => i.creator === group.id) || []).map((item, indexItem)=>(
                                                    <Chip key={indexItem} label={item.name} variant="outlined" />
                                                ))
                                            }
                                            {list.items.filter(i => i.creator === group.id).length < 1 && <Typography variant="caption">No items added</Typography>}
                                        </Box>
                                    </Paper>
                                )
                            })}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            })}
        </Box>
    )
}