import React, { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { TextField, Button, Box, Typography } from '@mui/material';
import BackgroundImage from '../../components/BackgroundImage';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import styles from "./LoginForm.module.css"

interface LoginFormInputs {
    username: string;
    password: string;
}

export const Login: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<LoginFormInputs>();

    const { loginWithUser, isLoading, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user && !isLoading) {
            navigate("/weddings");
        }
    }, [navigate, user, isLoading]);

    const handleLogIn = async (data: LoginFormInputs) => {
        const response = await loginWithUser(data.username, data.password);
        
        if (response.error) {
            alert(response.error);
        }
    };

    const onSubmit: SubmitHandler<LoginFormInputs> = data => {
        handleLogIn(data)
    };

    return (
        <>
            <BackgroundImage imageUrl={`/images/wallpaper1.webp`} />
            <div className={styles.structureLogin}>
                <div className={styles.containerLogin}>
                    <Typography variant='h4'>Log in</Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit(onSubmit)}
                        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: 300, margin: 'auto', mt: 5 }}
                    >
                        <TextField
                            label="Username"
                            {...register('username', { required: 'Username é obrigatório' })}
                            error={!!errors.username}
                            helperText={errors.username ? errors.username.message : ' '}
                            variant="outlined"
                            fullWidth
                            
                            slotProps={{
                                input: {
                                style: { backgroundColor: 'white' },
                                },
                            }}
                        />
                        <TextField
                            slotProps={{
                                input: {
                                style: { backgroundColor: 'white' },
                                },
                            }}
                            label="Password"
                            type="password"
                            {...register('password', { required: 'Senha é obrigatória' })}
                            error={!!errors.password}
                            helperText={errors.password ? errors.password.message : ' '}
                            variant="outlined"
                            fullWidth
                        />
                        <Button type="submit" variant="contained" color="primary">
                            Login
                        </Button>
                    </Box>
                </div>
            </div>
        </>
    );
};


