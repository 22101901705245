import React, { useState } from "react"
import { useWeddingAdmin } from "../../context/WeddingAdminProvider"
import { Button, Grid2, Typography, useMediaQuery, useTheme } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ResponsiblePaper } from "../wedding/components/ResponsiblePaper";


export const Description: React.FC<any> = ()=>{
    const theme = useTheme();
    // const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { wedding, weddingId, alertSnack } = useWeddingAdmin();
    const [description, setDescription] = useState<string>(wedding?.description || "")

    const handleSave = ()=>{
        const payload = { description }
        fetch(`/api/weddings/${weddingId}/description`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
        }).then((response)=>{
            if (response.ok) {
                alertSnack({text: "Success", type: "success"})
                return
            }
            alertSnack({text: "Error", type: "error"})
        }).catch((err)=>{
            alertSnack({text: "Error - Unknown", type: "error"})

        })
    }    

    return <>
        <ResponsiblePaper autoMargin fullWidth widthReduction={isMobile ? '2em' : '2em'} elevation={3} style={{marginTop: "2em"}}> 
            <Grid2 size={12} width={'100%'} maxWidth={1000} minWidth={300}>
                <Typography variant="h6">Description</Typography>
                <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={(value) => setDescription(value)}
                />
            </Grid2>
            <Grid2 size={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                >
                    Save
                </Button>
            </Grid2>
        </ResponsiblePaper>
    </>
}