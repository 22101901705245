
export const AgeTypeList: {[key in AgeType]: { label: string, key: AgeType}} = {
    "ERWACHSENE": { label:"Adult", key: 'ERWACHSENE'},
    "KIND": { label:"Child", key: 'KIND'},
    "BABY": { label:"Baby", key: 'BABY'},
}

export type AgeType = 
    "ERWACHSENE" |
    "KIND" |
    "BABY"

type QuestionOption = {
    id?: number;
    answer: string;
}

interface Question {
    id?: number;
    question: string;
    options: QuestionOption[];
}

export type AgeOption = {
    id?: number;
    description?: string;
    type: AgeType;
}

type FoodOption = {
    id?: number;
    description: string;
}

export type GiftOption = {
    id?: number;
    name: string;
    exclusive: boolean;
    buyers?: Group[];
    purchasedByMe?: boolean;
    available?: boolean;
}

type ContributionOption = {
    id?: number;
    name: string;
}

export interface ContributionList {
    id?: number;
    name: string;
    items: ContributionOption[];
}

export interface Group {
    id?: number;
    name: string;
    entryCode: string;
    guests?: Guest[]
    selectedOptions?: QuestionOption[]
} 

export type Guest = {
    id?: number;
    name: string;
    willAttend?: boolean;
    selectedAgeOption?: AgeOption | null;
    selectedFood?: FoodOption | null;
}

export type FontFamilyTitle = "Bilbo Swash Caps" | "Meow Script" | "Sacramento" | "Ms Madi" | "Pacifico" | "Roboto"
export const FontFamilyList: FontFamilyTitle[] = ["Bilbo Swash Caps", "Meow Script", "Sacramento", "Ms Madi", "Pacifico", "Roboto"]

export interface Wedding {
    id?: number;
    name: string;
    displayUrl: string;
    description: string;
    weddingAddress?: string;
    weddingDate?: Date | null;
    backgroundPicture: string | null;
    groups?: Group[] | null;
    infos: string;
    ageOptions: AgeOption[];
    foodOptions: FoodOption[];
    questions: Question[];
    giftOptions: GiftOption[];
    lists: ContributionList[];
    guestGroup?: Group | null;
    confirmBeforeDate?: Date | null;
    giftsDescription?: string;
    fontFamilyTitle?: FontFamilyTitle;
}