import React from 'react';

interface BackgroundImageProps {
    imageUrl: string;
    children?: React.ReactNode;
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({ imageUrl, children }) => {
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                zIndex: -1,
            }}
        >
            {children}
        </div>
    );
};

export default BackgroundImage;
