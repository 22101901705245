import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { AuthProvider } from './context/AuthProvider';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import lightTheme from './themes/lightTheme';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';

export const App: React.FC = ()=>{
    return (
        <ThemeProvider theme={lightTheme}>
            <CssBaseline/>
            <BrowserRouter>
                <AuthProvider>
                    <AppRoutes/>
                </AuthProvider>
            </BrowserRouter>
        </ThemeProvider>
    )
}

