import React, { useEffect, useState } from "react"
import { Box, Button, CircularProgress, FormControl, FormHelperText, Grid2, InputLabel, LinearProgress, MenuItem, Select, Skeleton, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { fileToBase64, blobToUrl, base64ToBlob } from "../../utils/blobImage";
import { FontFamilyList, FontFamilyTitle, Group, Wedding } from "../../utils/Wedding";
import { useWeddingAdmin } from "../../context/WeddingAdminProvider";
import { ResponsiblePaper } from "../wedding/components/ResponsiblePaper";
import { PictureWithName } from "../wedding/components/PictureWithName";
import imageCompression from "browser-image-compression";


export const MainSettings: React.FC<any> = ()=>{
    const theme = useTheme();
    // const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    
    const { wedding, weddingId, alertSnack } = useWeddingAdmin();
    const [name, setName] = useState<string>(wedding?.name || '');
    const [displayUrl, setDisplayUrl] = useState<string>(wedding?.displayUrl || '');
    const [pictureUrl, setPictureUrl] = useState(`/api/weddings/${weddingId}/background`);
    const [picture, setPicture] = useState<string | null>(null);
    const [fontFamilyTitle, setFontFamilyTitle] = useState<FontFamilyTitle>(`${wedding?.fontFamilyTitle || "Bilbo Swash Caps"}`);
    const [errors, setErrors] = useState({
        name: false,
        displayUrl: false,
    });
    const [loadingCompress, setLoadingCompress] = useState<boolean>(false)

    useEffect(()=>{
        picture && setPictureUrl(blobToUrl(base64ToBlob(picture)) || '')
    },[picture])

    const updateGeneral = ()=>{
        const data: Partial<Wedding> = {
            id: wedding?.id,
            name,
            displayUrl,
            fontFamilyTitle,
            backgroundPicture: picture
        }
        fetch(`/api/weddings/${weddingId}/general`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
        }).then((response)=>{
            if (response.ok) {
                alertSnack({text: "Success", type: "success"})
                return
            }
            alertSnack({text: "Error", type: "error"})
        }).catch((err)=>{
            alertSnack({text: "Error - Unknown", type: "error"})

        })
    }

    const validateFields = (): boolean => {
        const newErrors = {
            name: !name.trim(),
            displayUrl: !displayUrl.trim(),
        };
        setErrors(newErrors);
        return !Object.values(newErrors).includes(true);
    };

    const handleUpload = async (e: any) => {
        const file = e.target.files?.[0];
        if (!file) return;
        setLoadingCompress(true)

        try {
            const options = {
                maxSizeMB: 0.5, 
                useWebWorker: true,
            };
            const compressedFile = await imageCompression(file, options);

            // Converter o arquivo comprimido para Base64
            // const base64String = await imageCompression.getDataUrlFromFile(compressedFile);

            // setPicture(base64String); // Define a imagem no estado
            const base64String = await fileToBase64(compressedFile);
            setPicture(base64String);
        } catch (error) {
            alertSnack({text: "Error processing image", type: "error"})

        } finally {
            setLoadingCompress(false)
        }
    };

    const handleSave = ()=>{
        if (!validateFields()) return
        updateGeneral()
    }

    return (
        <>
        <ResponsiblePaper autoMargin fullWidth widthReduction={isMobile ? '2em' : '2em'} elevation={3} 
            style={{ padding: isMobile ? '10px' : '15px', maxWidth: '1000px', marginTop: "2em"  }}>
            <Grid2 container spacing={3}>
                <Grid2 size={12}>
                    <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        value={name}
                        onChange={(e)=>{
                            setName(e.target.value)
                            errors.name && setErrors(prev => ({...prev, name: false}))
                        }}
                        error={errors.name}
                        required
                    />
                </Grid2>
                <Grid2 size={12}>
                    <TextField
                        fullWidth
                        label="Display URL"
                        name="displayUrl"
                        value={displayUrl}
                        error={errors.displayUrl}
                        onChange={(e)=>{
                            setDisplayUrl(e.target.value)
                            errors.name && setErrors(prev => ({...prev, displayUrl: false}))
                        }}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-helper-label">Font</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={fontFamilyTitle}
                            label="Font"
                            onChange={(e)=>setFontFamilyTitle(e.target.value as FontFamilyTitle)}
                        >
                            {FontFamilyList.map((f, i)=> {
                                return <MenuItem key={i} value={f}>{f}</MenuItem>
                            })}
                        </Select>
                        <FormHelperText>With label + helper text</FormHelperText>
                    </FormControl>
                    <Grid2 size={12}>
                        <Button variant="contained" component="label">
                            Upload Background Picture
                            <input
                                type="file"
                                hidden
                                onChange={handleUpload}
                            />
                        </Button>
                        {loadingCompress && 
                            <Box display={"inline-flex"}>
                                <CircularProgress />
                                <Typography variant="body2">Compressing image...</Typography>
                            </Box>
                        }
                    </Grid2>
                </Grid2>
                <Grid2 size={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Grid2>
            </Grid2>
        </ResponsiblePaper>
        <ResponsiblePaper fullWidth autoMargin widthReduction={"2rem"} 
            style={{maxWidth: '1000px', marginTop: "2em", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Typography variant="h6">Live preview:</Typography>
            <PictureWithName url={pictureUrl} title={wedding?.name} fontTitle={fontFamilyTitle} loading={loadingCompress}/>
        </ResponsiblePaper>
        </>
    )
}