import { Box, Button, Card, CardContent, Typography, IconButton, AvatarGroup, Avatar, Input } from "@mui/material"
import React, { useState } from "react"
import QRCode from "react-qr-code"
import { Group, Guest } from "../../../utils/Wedding"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useWeddingAdmin } from "../../../context/WeddingAdminProvider"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
    group: Group;
    onRemove: ()=>void;
    onSave: (newName: string, guests: Guest[])=>void;
}

export const SingleInvitation: React.FC<IProps> = ({group, onRemove, onSave})=>{
    const { weddingId } = useWeddingAdmin()
    const [guests, setGuests] = useState<Guest[]>(group?.guests || [])
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [newName, setNewName] = useState<string>('')
    
    const handleAddGuest = () => {
        const newGuest = { name: `Person ${(guests?.length || 0) + 1}` }
        setGuests(prev => [...prev, newGuest])
    };
    
    const handleRemoveGuest = () => {
        if (guests.length && guests.length < 2) return
        setGuests(prev => prev.slice(0, -1));
    };

    const handleSave = ()=>{
        onSave(newName, guests)
        setIsEditing(false)
    }
    
    const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        setNewName(e.target.value)
    }

    const handleCancelChanges = ()=>{
        setIsEditing(false)
        setNewName(group.name)
        setGuests(group?.guests || [])
    }

    return (
        <Box sx={{ minWidth: 275, maxWidth: "16em" }}>
            <Card variant="outlined">
                <CardContent>
                    <Box display={'inline-flex'} justifyContent={'space-between'} sx={{width: '100%'}}>
                        {isEditing ? 
                        <Box display={"inline-flex"} justifyContent={'space-between'} width={"100%"}>
                            <Input defaultValue={group.name} value={newName} onChange={handleOnChangeName}/>
                            <Box display={"inline-flex"} gap={1}>
                                <IconButton onClick={handleCancelChanges} size="small">
                                    <CloseIcon />
                                </IconButton>
                                <IconButton onClick={handleSave} size="small">
                                    <SaveIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        :
                        <Box display={"inline-flex"} justifyContent={'space-between'} width={"100%"}>
                            <Typography variant="h6" component="div" textOverflow={"ellipsis"} noWrap>{group.name}</Typography>
                            <Box display={"inline-flex"} gap={1}>
                                <IconButton size="small" onClick={()=>{
                                    setIsEditing(true)
                                    setNewName(group.name)
                                }}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton size="small" onClick={onRemove}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        }
                    </Box>
                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
                        <Typography sx={{ color: 'text.secondary', mb: 0.5 }} textAlign={"center"}>{group.entryCode}</Typography>
                        <QRCode 
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }} 
                            value={`https://liebeimblick.wedding/${weddingId}?code=${group.entryCode}`} 
                            level="H"
                        />
                    </div>
                    <Box display={'flex'} flexDirection={"column"} justifyContent={'space-between'} sx={{width: '100%'}}>
                        <Box display={'inline-flex'} alignItems={'center'}>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>Number of guests:</Typography>
                            {isEditing && <>
                                <IconButton onClick={handleAddGuest}>
                                    <AddIcon />
                                </IconButton>
                                <IconButton onClick={handleRemoveGuest}>
                                    <RemoveIcon />
                                </IconButton>
                            </>}
                        </Box>
                        <AvatarGroup max={5} sx={{alignSelf: "flex-start", "& .MuiAvatarGroup-avatar": { width: 24, height: 24, fontSize: 14 }}}>
                            {guests?.map((guest, iGuest)=>{
                                return <Avatar key={iGuest} sx={{ width: 24, height: 24 }} alt="" src="/broken-image.jpg"/>
                            })}
                        </AvatarGroup>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}