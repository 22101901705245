import React from "react"
import { useNavigate } from "react-router-dom"
import { useWeddingAdmin } from "../../context/WeddingAdminProvider"
import { useAuth } from "../../context/AuthProvider"
import BackgroundImage from "../../components/BackgroundImage"
import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material"
import styles from "./WeddingList.module.css"
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const WelcomePage = ()=>{

    // const { wedding } = useWeddingAdmin()
    const navigate = useNavigate()
    const { logout } = useAuth()


    return (
        <div className={styles.structure}>
            <BackgroundImage imageUrl="/images/wallpaper1.webp"/>
            {/* <Box display={"flex"} flexDirection={"column"} gap={2}>
                {wedding.length > 0 && wedding.map((w, i ) => {
                    const urlPicture: string | undefined = `/api/weddings/${w.displayUrl}/background`

                    return (
                        <Card key={i} className={styles.card}>
                            <CardActionArea  sx={{height: "100%"}} onClick={()=>navigate(`/weddings/${w.displayUrl}`)}>
                                <Box display={"inline-flex"} sx={{width: "100%"}} alignItems={"center"}>
                                    <CardMedia component={"img"} image={urlPicture} alt="weddingFoto" sx={{maxWidth: '90px'}}/>
                                    <CardContent sx={{width: "100%", alignItems: "center", padding: "16px !important"}}>
                                        <Box display={"inline-flex"} gap={1} justifyContent={"space-between"} sx={{width: "100%"}} alignItems={'center'}>
                                            <Typography variant="h5">{w.name}</Typography>
                                            <KeyboardArrowRightIcon />
                                        </Box>
                                    </CardContent>
                                </Box>
                            </CardActionArea>
                        </Card>
                    )

                })}
            </Box>  */}
            <Button onClick={()=>navigate("/weddings/create")} variant="contained" color="primary" startIcon={<AddIcon />}>Create wedding</Button>
            <Button onClick={()=>logout()} variant="contained" color="secondary">Logout</Button>
        </div>
    )
}