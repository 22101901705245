import BackgroundImage from "../../components/BackgroundImage";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import Instruction1 from "./Instructions1";


export const Homepage = ()=>{
    const theme = useTheme();

    return (
        <>
            
            <BackgroundImage imageUrl="/images/wallpaper1.webp" />
            <Typography variant="h1" 
                sx={{
                    color: theme.palette.homepage.color, 
                    fontFamily: theme.palette.homepage.fontFamily,
                    textAlign: "center",
                    margin: "100px 0 0 0"
                }} 
            >
                Liebe im Blick
            </Typography>
            <Box
                sx={{
                    textAlign: "center",
                    margin: "50px auto",
                    padding: "20px",
                    maxWidth: "800px",
                    background: "rgba(255, 255, 255, 0.8)",
                    borderRadius: "8px",
                }}
            >
                <Typography variant="h5" sx={{ marginBottom: "20px" }}>
                    Suche Sie die Hochzeitseite? Sie finden sie in Ihrer Einladungskarte.
                </Typography>
                <Box 
                    sx={{ 
                        display: "flex", 
                        justifyContent: "space-around", 
                        alignItems: "center", 
                        marginBottom: "20px" 
                    }}
                >
                    <Instruction1/>
                </Box>
                <TextField 
                    label="Name des Hochzeitspaares" 
                    variant="outlined" 
                    fullWidth 
                    sx={{ marginBottom: "20px" }} 
                />
                <Button 
                    variant="contained" 
                    color="primary" 
                    sx={{ width: "100%" }}
                >
                    Hochzeit finden
                </Button>
            </Box>
        </>

    );
}