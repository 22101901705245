import { Snackbar, Alert } from "@mui/material"
import React, { useEffect, useState } from "react"


export interface SnackAdmin {
    open: boolean;
    text: string;
    type: "success" | "error" | "info" | "warning";
    onAutoClose: ()=>void
}

export const SnackbarAdmin: React.FC<SnackAdmin> = ({open, text, type, onAutoClose})=>{
    const [openLocal, setOpenLocal] = useState<boolean>(open)

    useEffect(()=>{
        setOpenLocal(open)
    },[open])

    useEffect(()=>{
        !openLocal && onAutoClose()
    },[openLocal])

    return (
        <Snackbar open={openLocal} autoHideDuration={5000} onClose={()=>setOpenLocal(false)}>
            <Alert
                onClose={()=>setOpenLocal(false)}
                severity={type}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {text}
            </Alert>
        </Snackbar>
    )
}