import React, { useEffect, useState } from "react"
import { useWeddingAdmin } from "../../context/WeddingAdminProvider"
import { Button } from "@mui/material"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from "react-router-dom";


export const Dashboard = ()=>{

    const { wedding, weddingId } = useWeddingAdmin()
    const urlPicture: string | undefined = weddingId ? `/api/weddings/${weddingId}/background` : "/broken-image.jpg"

    return (
        <>
            <Link to={`/${weddingId}`}><Button variant="contained" color="primary"><OpenInNewIcon />View wedding</Button></Link>
            <img height={"100px"} src={urlPicture} alt="" />
            <pre>DASHBOARD {JSON.stringify(wedding)}</pre>
        </>
    )
}