import { Box, Tooltip, IconButton, Avatar, Menu, MenuItem, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { useState } from "react";
import { useAuth } from "../../../context/AuthProvider";

type Items = "Logout" | string | null

export const UserMenu = ()=>{
    const { logout } = useAuth()
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const items: Items[] = ['Logout'];

    const handleClickItem = (item: Items)=>{
        if (item === 'Logout'){
            logout()
        }         
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 0 }}>
            <Tooltip title="Options">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="" sx={{ bgcolor: grey[500]}} src="/images/broken-image.jpg" />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {items.map((i) => (
                    <MenuItem key={i} onClick={()=>handleClickItem(i)}>
                        <Typography sx={{ textAlign: 'center' }}>{i}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )
}