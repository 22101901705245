import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface IAuthContext {
    user: UserData | null;
    groupUser: UserData | null;
    loginWithGroup: (entryCode: string, weddingId: string) => Promise<LoginResponse>;
    loginWithUser: (username: string, password: string) => Promise<LoginResponse>;
    register: (username: string, password: string) => Promise<LoginResponse>;
    logout: () => Promise<LoginResponse>;
    refetch: ()=>void;
    isLoading: boolean;
}

const AuthContext = createContext<IAuthContext | null>(null);

export const useAuth = () =>{
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth precisa ser dentro do auth provider");
    }
    return context;
}

interface IProps {
    children: ReactNode;
}

interface LoginResponse {
    success: boolean;
    error: string | null;
}

type UserData = {
    id: string
    username: string
    type: "USER" | "GROUP"
}


export const AuthProvider: React.FC<IProps> = ({ children }) => {
    const [user, setUser] = useState<UserData | null>(null);
    const [groupUser, setGroupUser] = useState<UserData | null>(null);
    const [refetchTrigger, setRefetchTrigger] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true); 

    console.log("user", user)
    console.log("user group", groupUser)

    const fetchUser = async () => {
        try {
            const response = await fetch("/api/auth/me", {
                method: "GET",
                credentials: "include",
            });

            if (response.ok) {
                const userData: UserData = await response.json();

                if (userData && userData.type === "USER"){
                    setUser(userData);
                    setGroupUser(null)
                } else if (userData && userData.type === "GROUP"){
                    setGroupUser(userData)
                    setUser(null);
                }
            } else {
                setUser(null);
                setGroupUser(null)
            }
        } catch (error) {
            setUser(null);
            setGroupUser(null)
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUser();
    }, [refetchTrigger]);

    const loginWithUser = async (username: string, password: string): Promise<LoginResponse> => {
        try {
            const response = await fetch('/api/auth/login/user', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password })
            });
            if (response.ok) {
                fetchUser()
                return { success: true, error: null }
            } else {
                setIsLoading(false)
                return { success: false, error: `Erro no login com usuario: ${response.statusText}`};
            }
        } catch (error) {
            setIsLoading(false)
            return { success: false, error: `Erro no login com usuario: ${error}`};
        }
    };

    const loginWithGroup = async (entryCode: string, weddingId: string): Promise<LoginResponse> => {
        try {
            const response = await fetch(`/api/auth/login/group?weddingId=${weddingId}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ entryCode, weddingId })
            });
            if (response.ok) {
                fetchUser()
                return { success: true, error: null }
            } else {
                setIsLoading(false)
                return { success: false, error: response.status === 401 ? "UNAUTHORIZED" : "UNKNOWN"};
            }
        } catch (error) {
            setIsLoading(false)
            return { success: false, error: error?.toString()?.toUpperCase() || "UNKNOWN"};
        }
    };

    const logout = async(): Promise<LoginResponse> => {
        try {
            const response = await fetch('/api/auth/logout', {
                method: 'POST',
            });
            if (response.ok) {
                setUser(null);
                setGroupUser(null)
                return { success: true, error: null }
                
            } else {
                return { success: false, error: `Erro no logout, ${response.statusText}`};
            }
        } catch (error) {
            return { success: false, error: `Erro no logout, ${error}`};
        } 
    };

    const register = async (username: string, password: string): Promise<LoginResponse> => {
        try {
            const response = await fetch('/api/auth/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password })
            });
            if (response.ok) {
                return { success: true, error: null }
            } else {
                return { success: false, error: `Erro ao registrar, ${response.statusText}`};
            }
        } catch (error) {
            return { success: false, error: `Erro ao registrar, ${error}`};
        }
    };

    return (
        <AuthContext.Provider value={{ 
            user, 
            groupUser, 
            loginWithGroup, 
            loginWithUser, 
            logout, 
            register, 
            refetch: ()=>setRefetchTrigger(prev=>!prev),
            isLoading,
        }}>
            {children}
        </AuthContext.Provider>
    );
};
