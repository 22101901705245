import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Homepage } from '../pages/homepage/Homepage';
import { WeddingLayout } from '../pages/wedding/WeddingLayout';
import { Login } from '../pages/user/Login';
import { RegisterUser } from '../pages/user/RegisterUser';
import AdminRoutes from './AdminRoutes';

const AppRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/:weddingId" element={<WeddingLayout />} />
            <Route path="/signin" element={<Login />} />
            <Route path="/signup" element={<RegisterUser />} />
            <Route path='/weddings/*' element={<AdminRoutes />}/>
        </Routes>
    );
};

export default AppRoutes;
