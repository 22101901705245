// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginForm_structureLogin__zwDD2 {
    height: 90dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LoginForm_containerLogin__y23D6 {
    padding: 50px;
    background-color: rgba(255, 255, 255, 0.712);
    width: fit-content;
    border-radius: 10px;

}`, "",{"version":3,"sources":["webpack://./src/pages/user/LoginForm.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,4CAA4C;IAC5C,kBAAkB;IAClB,mBAAmB;;AAEvB","sourcesContent":[".structureLogin {\n    height: 90dvh;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.containerLogin {\n    padding: 50px;\n    background-color: rgba(255, 255, 255, 0.712);\n    width: fit-content;\n    border-radius: 10px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"structureLogin": `LoginForm_structureLogin__zwDD2`,
	"containerLogin": `LoginForm_containerLogin__y23D6`
};
export default ___CSS_LOADER_EXPORT___;
