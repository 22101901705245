import React, { useEffect, useState } from "react"
import { useWeddingAdmin } from "../../context/WeddingAdminProvider"
import { Button, Grid2, Typography, Box, useMediaQuery, useTheme, Divider, ToggleButtonGroup, ToggleButton, TextField, IconButton, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { AgeOption, AgeType, AgeTypeList, Wedding } from "../../utils/Wedding";
import { ResponsiblePaper } from "../wedding/components/ResponsiblePaper";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';


export const AgeOptions: React.FC<any> = ()=>{
    const { wedding, weddingId, alertSnack, refetch, setWedding } = useWeddingAdmin()
    const theme = useTheme();
    // const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [selectedType, setSelectedType] = useState<AgeType>(AgeTypeList.ERWACHSENE.key)
    const [description, setDescription] = useState<string>('')

    
    const handleAdd = ()=>{
        if (!selectedType) return
        const ageOption: AgeOption = {
            description,
            type: selectedType
        } 
        fetch(`/api/weddings/${weddingId}/ageoptions`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(ageOption)
        }).then((response)=>{
            if (response.ok) return response.json()
            return
        }).then((dataResponse)=>{
            if (dataResponse?.ageOptionId){
                // Push AgeOption to Wedding
                const newAgeOption: AgeOption = {...ageOption, id: dataResponse.ageOptionId}
                const updatedWedding: Wedding | null = wedding
                ? {
                    ...wedding,
                    ageOptions: [...(wedding.ageOptions || []), newAgeOption],
                } : null;
                setWedding(updatedWedding);
                alertSnack({text: "Success", type: "success"})
                return
            }
            // Error, refetch
            refetch()
        }).catch((err)=>{
            alertSnack({text: "Error", type: "error"})
        }).finally(()=>{
            setSelectedType(AgeTypeList.ERWACHSENE.key)
            setDescription("")
        })
    }

    const handleTypeChangeAdd = (e: React.MouseEvent<HTMLElement, MouseEvent>, value: AgeType | null) => {
        value && setSelectedType(value)
    } 

    const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        setDescription(e.target.value)
    }

    const handleRemoveAgeOption = (id?: number) => {
        if (!id) return
        fetch(`/api/weddings/${weddingId}/ageoptions/${id}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
        }).then((response)=>{
            if (response.ok) {
                const oldAgeOption = wedding?.ageOptions.find(ao => ao.id === id);
                if (!oldAgeOption) {
                    refetch();
                    return;
                }
    
                const newAgeOptions = wedding?.ageOptions.filter(ao => ao.id !== id) || [];
                const updatedWedding: Wedding | null = wedding
                    ? {
                          ...wedding,
                          ageOptions: newAgeOptions,
                      }
                    : null;
    
                setWedding(updatedWedding);
                alertSnack({text: "Added", type: "success"})
            } else {
                refetch();
            }
        }).catch((err)=>{
            alertSnack({text: "Error", type: "error"})
        })
    }
    
    const handleTypeChange = (id: number | undefined, newType: AgeType) => {
        if (!id) return
        fetch(`/api/weddings/${weddingId}/ageoptions/${id}/type`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ type: newType })
        }).then((response)=>{
            if (response.ok){
                const oldAgeOption: AgeOption | undefined = wedding?.ageOptions.find(a => a.id === id)

                if (!oldAgeOption){
                    refetch()
                    return
                }

                const updatedAgeOptions: AgeOption[] = wedding?.ageOptions.map(ao =>
                    ao.id === id ? { ...ao, type: newType } : ao
                ) || [];
            
                const updatedWedding: Wedding | null = wedding ? {...wedding, ageOptions: updatedAgeOptions, } : null;
                setWedding(updatedWedding);
                alertSnack({text: "Success", type: "success"})
                return
            }
            // Error, refetch
            refetch()
        }).catch((err)=>{
            alertSnack({text: "Error", type: "error"})
        }).finally(()=>{
            
        })
    }
    
    return (
        <>
        <ResponsiblePaper autoMargin fullWidth widthReduction={isMobile ? '2em' : '2em'} elevation={3} style={{marginTop: "2em", height: "100%"}}>
            <Grid2 size={12}>
                <Typography variant="h6">Age Categories</Typography>
                <Typography variant="caption">Here you can define the age categories that each guest can choose from. Useful for knowing how to calculate the value and quantity for the restaurant in the future.</Typography>
                
            </Grid2>
            <Box display={"inline-flex"} marginTop={"1.5em"} gap={2}>
                <TextField
                    fullWidth
                    label="Description"
                    name="ageDescription"
                    value={description}
                    onChange={handleChangeDescription}
                    size="small"
                    placeholder="16 years or older"
                />
                <ToggleButtonGroup size="small" exclusive value={selectedType} onChange={handleTypeChangeAdd}>
                    <ToggleButton size="small" value={AgeTypeList.ERWACHSENE.key}>{AgeTypeList.ERWACHSENE.label}</ToggleButton>
                    <ToggleButton size="small" value={AgeTypeList.KIND.key}>{AgeTypeList.KIND.label}</ToggleButton>
                    <ToggleButton size="small" value={AgeTypeList.BABY.key}>{AgeTypeList.BABY.label}</ToggleButton>
                </ToggleButtonGroup>

                <Button color={"primary"} variant="contained" onClick={handleAdd}><AddIcon />Add</Button>
            </Box>
            <Divider orientation="horizontal" variant="fullWidth" sx={{mb: 1.5, mt: 1.5}}/>
            <Box display={'flex'} flexDirection={'row'} flexWrap={"wrap"} gap={2} sx={{overflowY: 'auto', maxHeight: '100%'}}>
                {wedding?.ageOptions?.sort((a, b) => a.type.localeCompare(b.type))?.map((option, i)=>{
                    return (
                        <ListItem key={i}
                            secondaryAction={
                                <IconButton onClick={()=>handleRemoveAgeOption(option.id)} edge="end" aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <ListItemButton>
                                <ListItemText primary={option.description} />
                                <ToggleButtonGroup size="small" exclusive value={option.type} onChange={(e, newType)=>handleTypeChange(option.id, newType)}>
                                    <ToggleButton size="small" value={AgeTypeList.ERWACHSENE.key}>{AgeTypeList.ERWACHSENE.label}</ToggleButton>
                                    <ToggleButton size="small" value={AgeTypeList.KIND.key}>{AgeTypeList.KIND.label}</ToggleButton>
                                    <ToggleButton size="small" value={AgeTypeList.BABY.key}>{AgeTypeList.BABY.label}</ToggleButton>
                                </ToggleButtonGroup>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </Box>
        </ResponsiblePaper> 
        </>
    )
}