import React, { useEffect, useState } from "react"
import { useWeddingAdmin } from "../../context/WeddingAdminProvider"
import { Button, Grid2, Typography, Box, useMediaQuery, useTheme, Divider } from "@mui/material";
import { Group, Guest } from "../../utils/Wedding";
import { ResponsiblePaper } from "../wedding/components/ResponsiblePaper";
import { SingleInvitation } from "./components/SingleInvitation";
import AddIcon from '@mui/icons-material/Add';

export const Invitations: React.FC<any> = ()=>{
    const { wedding, weddingId, alertSnack, refetch, setWedding } = useWeddingAdmin()
    const theme = useTheme();
    // const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [groups, setGroups] = useState<Group[]>(wedding?.groups || [])

    function generateDisplayUrl() {
        const c = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let code = '';
        for (let i = 0; i < 4; i++) {
            const index = Math.floor(Math.random() * c.length);
            code += c[index];
        }
        return code;
    }
console.log(groups)
    useEffect(()=>{
        setGroups(wedding?.groups || [])
    },[wedding?.groups])
    
    const handleAddGroup = ()=>{
        // Create with 1 Person
        const guest: Guest[] = [
            {name: `Person 1`, willAttend: false}
        ]

        const countGroups = groups?.length || 0
        const group: Group = {
            name: `Invitation ${countGroups + 1}`,
            entryCode: generateDisplayUrl(),
            guests: guest
        }

        fetch(`/api/weddings/${weddingId}/groups`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(group)
        }).then((response)=>{
            if (response.ok){
                return response.json()
            }
            alertSnack({text: "Error", type: "error"})
        }).then((data)=>{
            const id = data.groupId
            setGroups(prev => [...prev, {...group, id: id}])
            alertSnack({text: "Success", type: "success"})
        }).catch((err)=>{
            alertSnack({text: "Error - Unknown", type: "error"})

        })
    }

    const handleRemoveGroup = (groupId?: number) => {
        if (!groupId) return
        fetch(`/api/weddings/${weddingId}/groups/${groupId}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
            if (response.ok) {
                setGroups(prevGroups => prevGroups.filter((g) => g.id !== groupId));
                alertSnack({text: "removed Success", type: "success"})
                return
            }
            alertSnack({text: "Error", type: "error"})
        }).catch((err)=>{
            alertSnack({text: "Error - Unknown", type: "error"})
        })
        
    };

    const handleUpdateGroup = (newName: string, guests: Guest[], oldGroup: Group)=>{
        if (!newName || guests.length < 1) return
        const updatedGroup: Group = {
            ...oldGroup, 
            name: newName,
            guests 
        };

        fetch(`/api/weddings/${weddingId}/groups/${oldGroup.id}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedGroup)
        }).then((response)=>{
            if (response.ok) {
                alertSnack({text: "Update Success", type: "success"})
                refetch()
                return
            }
            alertSnack({text: "Error", type: "error"})
        }).catch((err)=>{
            refetch()
            alertSnack({text: "Error - Unknown", type: "error"})
        })
    }
    
    return (
        <>
        <ResponsiblePaper autoMargin fullWidth widthReduction={isMobile ? '2em' : '2em'} elevation={3} style={{marginTop: "2em", height: "100%"}}>
            <Grid2 size={12}>
                <Typography variant="h6">Invitations</Typography>
                <Button color={"primary"} variant="contained" onClick={handleAddGroup}><AddIcon />Add invitation</Button>
                <Divider orientation="horizontal" variant="fullWidth" sx={{m: 1}}/>
                <Box display={'flex'} flexDirection={'row'} flexWrap={"wrap"} gap={2} sx={{overflowY: 'auto', maxHeight: '100%'}}>
                    {groups.sort((a, b) => a.name.localeCompare(b.name))?.map((group, iGroup)=>{
                        return (
                            <SingleInvitation key={iGroup} 
                                group={group} 
                                onRemove={()=>handleRemoveGroup(group.id)}
                                onSave={(newName, guests)=>handleUpdateGroup(newName, guests, group)}
                            />
                        )
                    })}
                </Box>
            </Grid2>
        </ResponsiblePaper> 
        </>
    )
}